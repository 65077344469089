import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e950c2e = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _bc706e98 = () => interopDefault(import('../pages/daily_report.vue' /* webpackChunkName: "pages/daily_report" */))
const _3a6ca024 = () => interopDefault(import('../pages/driver_schedule.vue' /* webpackChunkName: "pages/driver_schedule" */))
const _7288b6fa = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _ebc43282 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7fc2b125 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _7e950c2e,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/daily_report/",
    component: _bc706e98,
    pathToRegexpOptions: {"strict":true},
    name: "daily_report"
  }, {
    path: "/driver_schedule/",
    component: _3a6ca024,
    pathToRegexpOptions: {"strict":true},
    name: "driver_schedule"
  }, {
    path: "/forget_password/",
    component: _7288b6fa,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/login/",
    component: _ebc43282,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _7fc2b125,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/",
    redirect: "/daily_report/",
    name: "index"
  }, {
    path: "*",
    redirect: "/daily_report/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
